import { types as T, make3 } from "./action_types";
import * as API from "../services/api";

// set route path
export function setRoute(data) {
  return {
    type: T.SET_ROUTE,
    data,
  };
}

// Login Admin
export function loginAdmin(data) {
  return {
    types: make3("LOGIN"),
    promise: () => {
      return API.login(data).then((result) => result);
    },
  };
}

// Forgot Paassword
export function forgotPassword(data) {
  return {
    types: make3("FORGOT_PASSWORD"),
    promise: () => {
      return API.forgotPassword(data).then((result) => result);
    },
  };
}

// Set Admin info
export function setAdminInfo(data) {
  return {
    type: T.SET_ADMININFO,
    data,
  };
}

// clear login data called when logout press
export function logout() {
  API.tokenStorage.remove();
  return {
    type: T.LOGOUT,
  };
}

// Get Dashboard Stats
export function loadAdminDashboardStats(partnerId) {
  return {
    types: make3("GET_DASHBOARD_STATS"),
    promise: () => {
      return API.getAdminDashboardStats(partnerId).then((result) => result);
    },
  };
}

// Get Reward Complete task list
export function loadCompleteTaskList() {
  return {
    types: make3("GET_COMPLETETASK_LIST"),
    promise: () => {
      return API.getCompleteTaskList().then((result) => result);
    },
  };
}

// Get Reward Complete task list
export function sendReward(data) {
  return {
    types: make3("SEND_REWARD_DATA"),
    promise: () => {
      return API.sendRewardData(data).then((result) => result);
    },
  };
}

// Get Reward Task List
export function loadRewardTaskList() {
  return {
    types: make3("GET_REWARD_LIST"),
    promise: () => {
      return API.getRewardTaskList().then((result) => result);
    },
  };
}

// Handle new signup action
export function saveNewSignupSettting(data) {
  return {
    types: make3("UPDATE_NEW_SIGNUP_SETTING"),
    promise: () => {
      return API.updateNewSignupSettting(data).then((result) => result);
    },
  };
}

// Handle cashback action
export function saveCashbackAmount(data) {
  return {
    types: make3("̈UPDATE_CASHBACK_AMOUNT"),
    promise: () => {
      return API.postCashbackAmount(data).then((result) => result);
    },
  };
}

// Get all the app users
export function loadUsers(data) {
  return {
    types: make3("GET_USERS"),
    promise: () => {
      return API.getAllUsers(data).then((result) => result);
    },
  };
}

// update the user data
export function updateUserAction(userId, data) {
  return {
    types: make3("UPDATE_USER_ACTION"),
    promise: () => {
      return API.postUserAction(userId, data).then((result) => result);
    },
  };
}

// Get the user data from the database
export function loadUserInfo(userId) {
  return {
    types: make3("VIEW_USER_INFO"),
    promise: () => {
      return API.getUserInfo(userId).then((result) => result);
    },
  };
}

// Empty active user
export function emptyActiveUser(userId) {
  return {
    types: make3("VIEW_USER_INFO"),
    promise: () => {
      return Promise.resolve({});
    },
  };
}

// Get the data from the database and from the truelayer as well.
export function loadUserLiveInfo(userId) {
  return {
    types: make3("VIEW_USER_INFO_LIVE"),
    promise: () => {
      return API.getUserLiveInfo(userId).then((result) => result);
    },
  };
}

// Get the info of the all the cards
export function loadCardCatalog() {
  return {
    types: make3("GET_CARD_CATALOG"),
    promise: () => {
      return API.getCardCatalog().then((result) => result);
    },
  };
}

// Update the card data
export function updateCardCatalogInfo(cardId, data) {
  return {
    types: make3("UPDATE_CARD_CATALOG"),
    promise: () => {
      return API.postCardCatalogInfo(cardId, data).then((result) => result);
    },
  };
}

// Get all the admin user
export function loadAdminUser() {
  return {
    types: make3("GET_ADMIN_USERS"),
    promise: () => {
      return API.getAllAdmins().then((result) => result);
    },
  };
}

// Update admin user info
export function updateAdminUser(action, id, data) {
  return {
    types:
      action === "edit"
        ? make3("GET_ADMIN_USERS_EDIT")
        : make3("GET_ADMIN_USERS"),
    promise: () => {
      if (action === "add")
        return API.postNewAdmin(data).then((result) => result);
      if (action === "edit")
        return API.updateAdmin(id, data).then((result) => result);
      if (action === "delete")
        return API.deleteAdmin(id).then((result) => result);
    },
  };
}

// Get all the admin user
export function updateUserCurrentPage(data) {
  return {
    types: make3("USER_CURRENT_PAGE"),
    promise: () => {
      return Promise.resolve(data);
    },
  };
}

export function loadMerchantIcons() {
  return {
    types: make3("GET_MERCHANT_ICONS"),
    promise: () => {
      return API.getMerchantIcons().then((result) => result);
    },
  };
}

export function updateMerchantIconInfo(iconId, data) {
  return {
    types: make3("UPDATE_MERCHANT_ICONS"),
    promise: () => {
      return API.postMerchantIconInfo(iconId, data).then((result) => result);
    },
  };
}

export function getMarketplaceClickInfo(partnerId) {
  return {
    types: make3("GET_MARKETPLACE_CLICK_INFO"),
    promise: () => {
      return API.getMarketplaceClickInfo(partnerId).then((result) => result);
    },
  };
}

export function loadTags() {
  return {
    types: make3("GET_TAGS"),
    promise: () => {
      return API.getTags().then((result) => result);
    },
  };
}

export function loadUniqueLoginHistory() {
  return {
    types: make3("GET_UNIQUE_LOGIN_DATA"),
    promise: () => {
      return API.getUniqueLoginHistory().then((result) => result);
    },
  };
}

export function loadMerchantListing(data) {
  return {
    types: make3("GET_MERCHANT_LISTING"),
    promise: () => {
      return API.getMerchantListing(data).then((result) => result);
    },
  };
}

export function loadCouncilTaxes(data) {
  return {
    types: make3("GET_COUNCIL_TAX"),
    promise: () => {
      return API.getCouncilTaxes(data).then((result) => result);
    },
  };
}

// Get General Tab Reports
export function loadGeneralTabReports(partnerId) {
  return {
    types: make3("GET_GENERAL_TAB_REPORTS"),
    promise: () => {
      return API.getGeneralTabReports(partnerId).then((result) => result);
    },
  };
}

export function loadFinancialTabReports(partnerId) {
  return {
    types: make3("GET_FINANCIAL_TAB_REPORTS"),
    promise: () => {
      return API.getFinancialTabReports(partnerId).then((result) => result);
    },
  };
}

export function loadPartnerOnboardingCodes() {
  return {
    types: make3("GET_PARTNER_ONBOARDING_CODE"),
    promise: () => {
      return API.getPartnerOnboardingCodes().then((result) => result);
    },
  };
}

// Save editable fields of admin reports tab
export function saveAdminEditableFields(data) {
  return {
    types: make3("̈UPDATE_ADMIN_FIELDS"),
    promise: () => {
      return API.updateAdminFields(data).then((result) => result);
    },
  };
}

// Get General Tab Reports
export function getConsumerDutyTabReports(partnerId) {
  return {
    types: make3("GET_CONSUMER_DUTY_TAB_REPORTS"),
    promise: () => {
      return API.getConsumerDutyTabReports(partnerId).then((result) => result);
    },
  };
}

// send admin report export request
export function exportAdminReport(data) {
  return {
    types: make3("GET_ADMIN_REPORT"),
    promise: () => {
      return API.adminReportPdf(data).then((result) => result);
    },
  };
}

export function loadSpinTheWheelData(passData) {
  return {
    types: make3("GET_SPIN_THE_WHEEL"),
    promise: () => {
      return API.getSpinTheWheelData(passData).then((result) => result);
    },
  };
}

export function loadUserVoucherData(passData) {
  return {
    types: make3("GET_USER_VOUCHER"),
    promise: () => {
      return API.getUserVoucherData(passData).then((result) => result);
    },
  };
}

export function updateUserVoucherInfo(data) {
  return {
    types: make3("UPDATE_VOUCHER_INFO"),
    promise: () => {
      return API.updateUserVoucherInfo(data).then((result) => result);
    },
  };
}

export function updateSpinTheWheelData(data) {
  return {
    types: make3("UPDATE_SPIN_WHEEL_INFO"),
    promise: () => {
      return API.updateSpinTheWheelInfo(data).then((result) => result);
    },
  };
}

export function addMerchantListing(data) {
  return {
    types: make3("ADD_MERCHANT_DATA"),
    promise: () => {
      return API.addMerchantData(data).then((result) => result);
    },
  };
}

export function deleteMerchantListingData(data) {
  return {
    types: make3("DELETE_MERCHANT_DATA"),
    promise: () => {
      return API.deleteMerchantData(data).then((result) => result);
    },
  };
}

export function updatePartnerActionAdmin(data) {
  return {
    types: make3("UPDATE_PARTNER_ACTION"),
    promise: () => {
      return API.updatePartnerAction(data).then((result) => result);
    },
  };
}

export function addEditPartnerDataAdmin(data) {
  return {
    types: make3("ADD_EDIT_PARTNER_ACTION"),
    promise: () => {
      return API.addEditPartner(data).then((result) => result);
    },
  };
}

export function getSubCode(data) {
  return {
    types: make3("GET_SUB_CODE_LIST"),
    promise: () => {
      return API.getSubCodeList(data).then((result) => result);
    },
  };
}

export function getUsedSubCode(data) {
  return {
    types: make3("GET_USED_SUB_CODE_LIST"),
    promise: () => {
      return API.getUsedSubCodeList(data).then((result) => result);
    },
  };
}

export function deleteSubCode(data) {
  return {
    types: make3("DELETE_SUB_CODE"),
    promise: () => {
      return API.deleteSubCode(data).then((result) => result);
    },
  };
}

export function deleteSubUsedCode(data) {
  return {
    types: make3("DELETE_USED_SUB_CODE_LIST"),
    promise: () => {
      return API.deleteUsedSubCodeList(data).then((result) => result);
    },
  };
}

export function pauseSubUsedCode(data) {
  return {
    types: make3("DELETE_USED_SUB_CODE_LIST"),
    promise: () => {
      return API.pauseUsedSubCodeList(data).then((result) => result);
    },
  };
}

export function addSubCode(data) {
  return {
    types: make3("ADD_SUB_CODE"),
    promise: () => {
      return API.addSubCode(data).then((result) => result);
    },
  };
}

//cashback Retailers
export function cashbackRetailersList(data) {
  return {
    types: make3("GET_CASHBACK_RETAILERS_LIST"),
    promise: () => {
      return API.getcashbackRetailerList(data).then((result) => result);
    },
  };
}

export function deleteRetailer(data) {
  return {
    types: make3("DELETE_RETAILER"),
    promise: () => {
      return API.deleteRetailer(data).then((result) => result);
    },
  };
}
export function addUpdateRetailer(data) {
  return {
    types: make3("ADD_UPDATE_RETAILER"),
    promise: () => {
      return API.addUpdateRetailer(data).then((result) => result);
    },
  };
}

export function isactiveRetailer(data) {
  return {
    types: make3("ISACTIVE_RETAILER"),
    promise: () => {
      return API.isactiveRetailer(data).then((result) => result);
    },
  };
}

export function tilloAdminSyncData() {
  return {
    types: make3("GET_TILLO_SYNC_DATA"),
    promise: () => {
      return API.tilloSyncAdminData().then((result) => result);
    },
  };
}

export function updateUsercomment(data) {
  return {
    types: make3("UPDATE_USER_COMMENT"),
    promise: () => {
      return API.updateUsercomment(data).then((result) => result);
    },
  };
}

export function UserBills(userId) {
  return {
    types: make3("GET_USER_BILLS"),
    promise: () => {
      return API.usersBills(userId).then((result) => result);
    },
  };
}

export function updateCouncilInfo(data) {
  return {
    types: make3("UPDATE_COUNCIL_INFO"),
    promise: () => {
      return API.updateCouncilTaxInfo(data).then((result) => result);
    },
  };
}

export function getUserGiftLogs(data) {
  console.log("ACTION", data);
  return {
    types: make3("GET_USER_GIFT_LOGS"),
    promise: () => {
      return API.getUserGiftLogsAdmin(data).then((result) => result);
    },
  };
}

export function loadMoneyplusDueData() {
  return {
    types: make3("GET_MONEYPLUS_DUE_DATA"),
    promise: () => {
      return API.getMoneyplusDueData().then((result) => result);
    },
  };
}

export function removeMoneyplusEntry(id) {
  return {
    types: make3("REMOVE_MONEYPLUS_ENTRY"),
    promise: () => {
      return API.hideMoneyplusData(id).then((result) => result);
    },
  };
}

export function loadNotificationData() {
  return {
    types: make3("GET_NOTIFICATION_BROADCAST_DATA"),
    promise: () => {
      return API.loadNotiBroadcastData().then((result) => result);
    },
  };
}

export function saveNotificationData(data) {
  return {
    types: make3("SAVE_NOTIFICATION_BROADCAST_DATA"),
    promise: () => {
      return API.saveNotiBroadcastData(data).then((result) => result);
    },
  };
}
