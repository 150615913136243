import { Col, Row, Table, Button } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import { getPaidStatus } from "../../common/utils";
import { notifyError, notifySuccess } from "../../common/notification";
import CustomModal from "../../components/CustomModal";
import moment from "moment";

// It's shows the card catalog info
class index extends Component {
  state = {
    removeDataModal: false,
    removeDataId: null,
  };
  componentDidMount() {
    this.props.loadMoneyplusDueData();
  }

  handleRemoveModal = (id) => {
    const { removeDataModal } = this.state;
    if (id) {
      this.setState({
        removeDataModal: !removeDataModal,
        removeDataId: id,
      });
    } else {
      this.setState({
        removeDataModal: !removeDataModal,
        removeDataId: null,
      });
    }
  };

  removeActionSubmit = () => {
    this.props
      .removeMoneyplusEntry({ id: this.state.removeDataId })
      .then((res) => {
        if (res && res.data && res.data.status) {
          this.setState({
            removeDataModal: false,
            removeDataId: null,
          });
          notifySuccess(res?.data?.message || "Done");
          this.props.loadMoneyplusDueData(null);
        } else {
          var errorMessage =
            res?.data?.message || res?.err?.message || "Something went wrong";
          notifyError(errorMessage);
        }
      });
  };

  // Render the card info in table formate
  columns = [
    {
      title: "#",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (_text, record) => (
        <p
          style={{
            width: 50,
          }}
        >
          {record.sr_no || ""}
        </p>
      ),
    },
    {
      title: "Email",
      dataIndex: "u_email_id",
      key: "u_email_id",
      render: (_text, record) => (
        <p
          style={{
            width: 250,
          }}
        >
          {record.u_email_id || ""}
        </p>
      ),
    },
    {
      title: "Payment due date",
      dataIndex: "due_date",
      key: "due_date",
      render: (_text, record) => (
        <p
          style={{
            width: 150,
          }}
        >
          {record?.current_due_date}
        </p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "current_due",
      key: "current_due",
      render: (_text, record) => (
        <p
          style={{
            width: 100,
          }}
        >
          {record?.current_amount}
        </p>
      ),
    },
    {
      title: "Reference",
      dataIndex: "account_number",
      key: "account_number",
      render: (_text, record) => (
        <p
          style={{
            width: 100,
          }}
        >
          {record.account_number || ""}
        </p>
      ),
    },
    {
      title: "Last active",
      dataIndex: "last_login_date",
      key: "last_login_date",
      render: (_text, record) => (
        <p
          style={{
            width: 150,
          }}
        >
          {moment(record?.last_login_date, "YYYY-MM-DD HH:mm:ss").format(
            "YYYY-MM-DD"
          ) || "--"}
        </p>
      ),
    },
    // {
    //   title: "User number",
    //   dataIndex: "user_unique_id",
    //   key: "user_unique_id",
    //   render: (_text, record) => (
    //     <p
    //       style={{
    //         width: 100,
    //       }}
    //     >
    //       {record.user_unique_id || ""}
    //     </p>
    //   ),
    // },
    {
      title: "Paid",
      dataIndex: "user_unique_id",
      key: "user_unique_id",
      render: (_text, record) => (
        <p
          style={{
            width: 150,
          }}
        >
          {record?.current_status}
        </p>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        return (
          <div className="btn-actions">
            <span
              style={{
                width: 50,
              }}
              onClick={(e) => {
                this.handleRemoveModal(record.user_partner_debts_master_id);
              }}
            >
              Remove
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const { moneyplusDueData } = this.props;
    const { removeDataModal } = this.state;
    console.log("moneyplusDueData ==>", moneyplusDueData);
    let results = [];
    if (moneyplusDueData && moneyplusDueData.length > 0) {
      for (var i = 0; i < moneyplusDueData.length; i++) {
        var key = `money-plus-data-user-${moneyplusDueData[i].user_partner_debts_master_id}`;

        let currentData = getPaidStatus(moneyplusDueData[i]?.installmentData);

        results.push({
          sr_no: i + 1,
          key: key,
          current_due_date: currentData?.due_date || "NA",
          current_amount: currentData?.amount || "NA",
          current_status: currentData?.paid_status || "NA",
          ...moneyplusDueData[i],
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Moneyplus Users ({results.length})</h4>
          </Col>
        </Row>
        <Row className="card-table">
          <Table
            dataSource={results}
            columns={this.columns}
            sortOrder={false}
            pagination={false}
          />
        </Row>
        <CustomModal
          className={"signup-setting-modal"}
          visible={removeDataModal}
          onClose={() => {
            this.handleRemoveModal(null);
          }}
        >
          <p className="txt-title">Remove/hide this entry?</p>
          <p className="txt-info">This change cannot be undone.</p>

          <Button type="primary" block onClick={this.removeActionSubmit}>
            Delete
          </Button>
        </CustomModal>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    moneyplusDueData: state.moneyplusDueData,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
